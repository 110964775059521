<template>
  <div class="inline-block">
    <edit
      :model-value="modelValue"
      :index="index"
      @update:modelValue="(v) => $emit('update:modelValue', v)"
      @save-requested="handleSave"
      @close-requested="closeHooks"
    />

    <el-button type="primary" plain icon="View" @click="openHooks">
      {{ $t('pages.settings.custom_dashboards.dashboards.form.hooks.label') }}
      ({{ modelValue.length }})
    </el-button>
  </div>
</template>

<script>
import Edit from './edit'

export default {
  components: {
    Edit
  },
  props: {
    modelValue: {
      type: Array,
      required: false,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  mounted() {},
  methods: {
    openHooks() {
      this.$thModal.show('hooks-' + this.index)
    },
    closeHooks() {
      this.$thModal.hide('hooks-' + this.index)
    },
    handleSave() {
      this.closeHooks()
      this.$emit('save-requested')
    }
  }
}
</script>

<style scoped>
.hooks-modal :deep(.v--modal-box) {
  position: absolute !important;
  height: auto !important;
  width: 900px !important;
  top: 50% !important;
  left: 50% !important;
  max-width: 90%;
  max-height: 500px;
  transform: translate(-50%, -50%);
}

.hooks-modal :deep(.el-container) {
  max-height: 500px;
}
</style>
