<template>
  <dashboard-list />
</template>

<script>
import DashboardList from './components/dashboard-list'

export default {
  components: {
    DashboardList
  },
  metaInfo() {
    return {
      title: this.$t('pages.settings.custom_dashboards.title')
    }
  }
}
</script>
