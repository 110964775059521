<template>
  <th-wrapper
    :title="$t('pages.settings.custom_dashboards.dashboards.title')"
    :subtitle="$t('pages.settings.custom_dashboards.dashboards.explainer')"
  >
    <!-- Enable -->
    <div class="mb-6">
      <el-switch
        v-model="enabled"
        :active-text="
          $t('pages.settings.custom_dashboards.dashboards.form.enabled.label')
        "
      >
        <el-input v-model="enabled" />
      </el-switch>
    </div>

    <div :class="{ '-mb-6': dashboards.length !== 0 }">
      <el-form v-for="(item, index) in dashboards" :key="index" :model="item">
        <el-row :gutter="20">
          <!-- Name -->
          <el-col :lg="5" :md="12">
            <el-form-item
              :label="
                $t(
                  'pages.settings.custom_dashboards.dashboards.form.name.label'
                )
              "
            >
              <el-input
                v-model="item.name"
                :placeholder="
                  $t(
                    'pages.settings.custom_dashboards.dashboards.form.name.placeholder'
                  )
                "
                @input="(v) => handleInput(v, 'dashboards', index, 'name')"
              />
            </el-form-item>
          </el-col>

          <!-- URL -->
          <el-col :lg="5" :md="12">
            <el-form-item
              :label="
                $t('pages.settings.custom_dashboards.dashboards.form.url.label')
              "
            >
              <el-input
                v-model="item.url"
                :placeholder="
                  $t(
                    'pages.settings.custom_dashboards.dashboards.form.url.placeholder'
                  )
                "
                @input="(v) => handleInput(v, 'dashboards', index, 'url')"
              />
            </el-form-item>
          </el-col>

          <el-col :lg="14" class="flex items-center">
            <!-- Enabled -->
            <el-form-item
              :label="
                $t(
                  'pages.settings.custom_dashboards.dashboards.form.enabled.label'
                )
              "
              class="inline-block"
            >
              <el-switch
                v-model="item.enabled"
                @change="(v) => handleInput(v, 'dashboards', index, 'enabled')"
              >
                <el-input
                  v-model="item.enabled"
                  @input="(v) => handleInput(v, 'dashboards', index, 'enabled')"
                />
              </el-switch>
            </el-form-item>

            <div class="inline-block ml-4">
              <!-- Hooks -->
              <hooks
                v-model="item.hooks"
                :index="index"
                @update:modelValue="
                  (v) => handleInput(v, 'dashboards', index, 'hooks')
                "
              />

              <!-- Delete -->
              <el-button
                icon="Delete"
                plain
                class="ml-2 el-button--text-icon"
                @click="removeItem(item, index, 'dashboards')"
              />

              <!-- Add -->
              <el-button
                v-if="index === dashboards.length - 1"
                class="el-button--primary-icon"
                plain
                icon="Plus"
                @click="addDashboard"
              />
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- Add when empty -->
    <el-button
      v-if="dashboards.length === 0"
      type="primary"
      plain
      icon="Plus"
      class="px-3"
      @click="addDashboard"
    >
      {{ $t('common.interactions.buttons.add') }}
    </el-button>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import cloneDeep from 'clone-deep'
import Hooks from './hooks'

export default {
  name: 'SettingsCustomDashboards',
  components: {
    Hooks
  },
  data() {
    return {}
  },
  computed: {
    enabled: {
      get() {
        return (
          safeGet(
            this.$store.state.Config.clientAccountConfiguration,
            'custom_dashboards.enabled'
          ) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'custom_dashboards.enabled',
          value: newValue
        })
      }
    },
    dashboards: {
      get() {
        return (
          cloneDeep(
            safeGet(
              this.$store.state.Config.clientAccountConfiguration,
              'custom_dashboards.dashboards'
            )
          ) || []
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: 'custom_dashboards.dashboards',
          value: newValue
        })
      }
    }
  },
  methods: {
    handleInput(newValue, itemsPath, index, path) {
      const items = cloneDeep(this[itemsPath])
      items[index] = {
        ...items[index],
        [path]: newValue
      }

      this[itemsPath] = items
    },
    addDashboard() {
      this.dashboards = [
        ...this.dashboards,
        {
          enabled: true,
          name: null,
          url: null
        }
      ]
    },
    removeItem(item, index, targetListName) {
      this[targetListName] = this[targetListName].filter(
        (_, _index) => _index !== index
      )
    }
  }
}
</script>
