<template>
  <th-modal
    width="800px"
    :name="'hooks-' + index"
    :title="$t('pages.configurations.custom_dashboards.hooks.edit.modal.title')"
  >
    <div
      class="flex-grow flex-shrink"
      :class="{ 'pb-1': modelValue.length !== 0 }"
    >
      <el-form v-for="(item, i) in modelValue" :key="i" :model="item">
        <el-row :gutter="20">
          <!-- Options -->
          <el-col :lg="4" :md="12">
            <el-form-item
              :label="
                $t('pages.settings.custom_dashboards.hooks.form.app.label')
              "
            >
              <el-select
                v-model="item.app"
                class="w-full"
                :placeholder="
                  $t(
                    'pages.settings.custom_dashboards.hooks.form.app.placeholder'
                  )
                "
                @change="(v) => handleInput(v, i, 'app')"
              >
                <el-option
                  v-for="app in appOptions"
                  :key="app.value"
                  :label="app.label"
                  :value="app.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Position -->
          <el-col :lg="3" :md="12">
            <el-form-item
              :label="
                $t('pages.settings.custom_dashboards.hooks.form.position.label')
              "
            >
              <el-select
                v-model="item.position"
                class="w-full"
                :placeholder="
                  $t(
                    'pages.settings.custom_dashboards.hooks.form.position.placeholder'
                  )
                "
                @change="(v) => handleInput(v, i, 'position')"
              >
                <el-option
                  v-for="position in positionOptions"
                  :key="position.value"
                  :label="position.label"
                  :value="position.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <!-- Locations -->
          <el-col :lg="12" :md="19" :xs="18">
            <el-form-item
              :label="
                $t(
                  'pages.settings.custom_dashboards.hooks.form.locations.label'
                )
              "
            >
              <el-select
                v-model="item.locations"
                multiple
                clearable
                class="w-full"
                :placeholder="
                  $t(
                    'pages.settings.custom_dashboards.hooks.form.locations.placeholder'
                  )
                "
                @change="(v) => handleInput(v, i, 'locations')"
              >
                <el-option
                  v-for="location in locationsOptions"
                  :key="location.value"
                  :label="location.label"
                  :value="location.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :lg="5" :md="5" :xs="6">
            <el-form-item label="Actions" class="hidden-label">
              <!-- Delete -->
              <el-button
                plain
                icon="Delete"
                class="-ml-2 el-button--text-icon"
                @click="removeItem(item, i)"
              />

              <!-- Add -->
              <el-button
                v-if="i === modelValue.length - 1"
                class="el-button--primary-icon"
                plain
                icon="Plus"
                @click="addDashboard"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- Add when empty -->
      <el-button
        v-if="modelValue.length === 0"
        type="primary"
        plain
        icon="Plus"
        class="px-3"
        @click="addDashboard"
      >
        {{ $t('common.interactions.buttons.add') }}
      </el-button>
    </div>

    <!-- Footer -->
    <template #footer>
      <!-- Save -->
      <el-button type="primary" @click="handleSave">
        {{ $t('common.interactions.buttons.save') }}
      </el-button>
    </template>
  </th-modal>
</template>

<script>
import cloneDeep from 'clone-deep'

export default {
  props: {
    index: {
      type: Number,
      required: true
    },
    modelValue: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      appOptions: [
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.app.options.dashboard.label'
          ),
          value: 'dashboard'
        }
      ],
      positionOptions: [
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.position.options.first.label'
          ),
          value: 'first'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.position.options.last.label'
          ),
          value: 'last'
        }
      ],
      locationsOptions: [
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav.label'
          ),
          value: 'nav'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_reports.label'
          ),
          value: 'nav.reports'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_reports_statistics.label'
          ),
          value: 'nav.reports.statistics'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_reports_financial_accounting.label'
          ),
          value: 'nav.reports.financial_accounting'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_reports_exports.label'
          ),
          value: 'nav.reports.exports'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_products.label'
          ),
          value: 'nav.products'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_customers.label'
          ),
          value: 'nav.customers'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_vouchers.label'
          ),
          value: 'nav.vouchers'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_staff.label'
          ),
          value: 'nav.staff'
        },
        {
          label: this.$t(
            'pages.settings.custom_dashboards.hooks.form.location.options.nav_utilities.label'
          ),
          value: 'nav.utilities'
        }
      ]
    }
  },
  methods: {
    handleSave() {
      this.$emit('save-requested')
    },
    handleCancel() {
      this.$emit('close-requested')
    },
    handleInput(newValue, index, path) {
      const items = cloneDeep(this.modelValue)

      items[index] = {
        ...items[index],
        [path]: newValue
      }

      this.$emit('update:modelValue', items)
    },
    addDashboard() {
      this.$emit('update:modelValue', [
        ...this.modelValue,
        {
          app: 'dashboard',
          position: 'last',
          locations: ['nav']
        }
      ])
    },
    removeItem(item, index) {
      this.$emit(
        'input',
        this.modelValue.filter((_, _index) => _index !== index)
      )
    }
  }
}
</script>
